// Navigation bar and main background 
$mainScreenBackgroundColor: #F1F1F1;
$navigationBarColor: #FFFFFF;
$lettersColor: #102F47;
$hoverColor: #DBDEFC;
$elementsHoverColor: #DBDEFC;
$mainFont: "Noto Sans", sans-serif;
$navigationFontSize: 15px;
$navigationFontSizeMobileMenu: 10px;
$fontWeight_Diversset: 600;
$fontWeight_NavigationLetters: 400;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: $mainScreenBackgroundColor;
}

.navigationBar {
  background-color: $navigationBarColor;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  height: 70px;
  z-index: 9999;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.imageWithText {
  margin-left: 30px;
  display: flex;
  align-items: center;
}

.imageWithText2 {
  margin-right: 30px;
  display: flex;
  align-items: center;
}

.image {
  max-width: 18.50px;
  height: auto;
  margin-right: 7.50px;
}

.image2 {
  max-width: 11px;
  height: auto;
  margin-right: 7.50px;
}

.h3Elements {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
  width: 100%;
}

h1 {
  font-family: $mainFont;
  font-size: $navigationFontSize;
  margin: 0;
  text-align: center;
  font-weight: $fontWeight_Diversset;
  color: $lettersColor;
}

h3 {
  margin: 0;
  margin-right: 60px;
  font-size: $navigationFontSize;
  font-family: $mainFont;
  font-weight: $fontWeight_NavigationLetters;
  color: $lettersColor;
  text-align: center;
}

h4 {
  margin: 0;
  margin-right: 30px;
  font-size: $navigationFontSizeMobileMenu;
  font-family: $mainFont;
  font-weight: $fontWeight_NavigationLetters;
  color: $lettersColor;
  text-align: center;
}


.linkColor {
  text-decoration: none;
  color: $lettersColor;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;
}

.linkColor:hover {
  color: $hoverColor;
}

#education {
  margin-left: 383px;
}

#help {
  margin-right: 383px;
}

.toggleButton {
  text-align: center;
  margin-top: 0px;

}

.menuButton {
  border: none;
  background-color: $navigationBarColor;
  padding: 0;
  cursor: pointer;
  transition: color 0.3s;
}

.menuText {
  color: $lettersColor;
  transition: color 0.3s;
}

.menuButton.active {
  background-color: $navigationBarColor;
}

.menuButton:hover .menuText {
  color: $hoverColor;
}


/* Style for the second navigation bar */
.secondNavBar {
  background-color: $navigationBarColor;
  position: absolute;
  top: 70px;
  height: 35px;
  width: 100%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: $navigationFontSizeMobileMenu;

}

.secondNavBar .h3Elements {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  left: 50%;
}

@media only screen and (max-width: 1060px) {
  h3 {
    margin-right: 30px;
  }

}

@media only screen and (max-width: 950px) {
  .h3Elements {
    left: 60%;
  }
}

@media only screen and (max-width: 740px) {
  .h3Elements {
    left: 60%;

  }
}

@media only screen and (max-width: 580px) {
  .secondNavBar .h3Elements {
    gap: 0px;
  }

  h4 {
    // font-size: 10px;
    margin-right: 8px;

  }

  .secondNavBar {
    top: 50px;
    height: 50px;
  }

  .linkColor {
    font-size: $navigationFontSizeMobileMenu;
  }
}



//Main screen design.

.elementsContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  min-height: 90vh;
  padding: 0 20px;
}

.texts,
.imageContainer {
  text-align: center;
}

.imageBackground {
  max-width: 700px;
  height: auto;
  display: block;
  margin-right: calc(50% - 50vw);
}

.imageContainer {
  overflow: hidden;
  width: 100%;
  height: auto;
}

.head-text {
  font-family: $mainFont;
  font-size: $navigationFontSize * 2;
  color: $lettersColor;
  max-width: 80%;
  margin-left: 10%;
  margin-bottom: 30px;
}

.body-text {
  font-family: $mainFont;
  font-size: $navigationFontSize;
  color: $lettersColor;
  font-weight: $fontWeight_NavigationLetters;
  line-height: 2.5;
  max-width: 80%;
  margin-left: 10%;
  text-align: justify;
}


.tryApp {
  width: 200px;
  height: 40px;
  margin-top: 5%;
  background-color: $mainScreenBackgroundColor;
  color: $lettersColor;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-family: $mainFont;
  font-size: $navigationFontSize;
  color: $lettersColor;
  font-weight: $fontWeight_NavigationLetters;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
}

.tryApp:hover {
  background-color: $hoverColor;
}

.tryApp:active {
  background-color: $navigationBarColor;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 1250px) {
  .head-text {
    font-size: $navigationFontSize
  }

  .imageBackground {
    max-width: 850px;
    height: auto;
    overflow: hidden;

  }
}

@media screen and (max-width: 768px) {
  .elementsContainer {
    grid-template-columns: 1fr;
    min-height: auto;
    padding: 20px;
    margin-top: 20px;
  }

  .tryApp {
    margin-left: auto;
    margin-right: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    width: 150px;
    height: 35px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  }

  .tryApp:active {
    box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.3);
  }


}

//Help screen
.upperContentAndButton {
  position: relative;
  width: 100%;
  text-align: center;
  // margin-bottom: 1rem;
  padding: 0.5rem 0;

}

.tryApp2 {
  width: 200px;
  height: 40px;
  margin-top: 20px;
  background-color: $mainScreenBackgroundColor;
  color: $lettersColor;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-family: $mainFont;
  font-size: $navigationFontSize;
  color: $lettersColor;
  font-weight: $fontWeight_NavigationLetters;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  margin-left: 40px;
}

.tryApp2:hover {
  background-color: $hoverColor;
}

.tryApp2:active {
  background-color: $navigationBarColor;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}


.helpLabel {
  font-size: 1.5rem;
  font-weight: bold;
  // margin-bottom: 1rem;
  margin-top: 40px;
  margin-right: 250px;
  flex: 1;
  text-align: center;
}

.subHeading {
  // margin-top: 10px;
  // margin-bottom: 1rem;
  text-align: center;
  margin-right: 0;

}

.imageBackgroundX {
  height: auto;
  max-width: 20px;
  display: block;
  margin-left: 20px;
}

.xImageContainer {
  overflow: hidden;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.twitterText {
  flex: 1;
  text-align: left;
  margin-left: 20px;
}

.xImageContainer2 {
  overflow: hidden;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.texts2 {
  text-align: start;
}

.elementsContainer2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-start;
  padding: 0 20px;
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .elementsContainer2 {
    grid-template-columns: 1fr;
    min-height: auto;
    padding: 20px;
    margin-top: 20px;
  }

  .xImageContainer2 {
    margin-bottom: 20px;
  }

  .tryApp2 {
    margin-left: auto;
    margin-left: 40px;
    margin-top: 40px;
    // margin-bottom: 10px;
    width: 150px;
    height: 35px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  }

  .tryApp2:active {
    box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.3);
  }

  .helpLabel {
    margin-right: 140px;
  }
}

//iOS apps screen
.contentsWithApps {
  margin-top: 40px;
}


.image-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  /* Add some space between image rows */
}

.image-box {
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.image-box img {
  max-width: 100%;
  height: auto;
}

.image-description {
  margin-top: 10px;
  font-weight: bold;
}

.image-button {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
}

.image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center items horizontally */
  justify-content: center;
  /* Center items vertically */
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.image-button {
  margin-top: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  width: 200px;
  height: 40px;
  margin-top: 40px;
  background-color: $mainScreenBackgroundColor;
  color: $lettersColor;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-family: $mainFont;
  font-size: $navigationFontSize;
  color: $lettersColor;
  font-weight: $fontWeight_NavigationLetters;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  // margin-left: 40px;
}



.image-button:hover {
  background-color: $hoverColor;
}

.image-button:active {
  background-color: $navigationBarColor;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}




.image-box1,
.image-box3,
.image-box4 {
  overflow: hidden;
  flex: 1;
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  /* Center items horizontally */
  justify-content: center;
  /* Center items vertically */
  border: 1px solid #ccc;
  padding: 10px;
  text-align: center;
}

.image-box1 img {
  max-width: 124%;
  height: auto;
}

.image-box3 img {
  max-width: 90%;
  height: auto;
  margin-top: 20px;
  margin-bottom: 41px;
}

.image-box4 img {
  max-width: 92%;
  height: auto;
}

.text-input {
  flex: 1;
  width: 60%;
  /* Make the input take up full width */
  height: 50px;
  /* Adjust the height as needed */
  padding: 8px;
  /* Add padding for a better appearance */
  margin-left: 40px;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .image-container {
    flex-direction: column;
    /* Stack image boxes vertically */
  }

  .image-box,
  .image-box1,
  .image-box3,
  .image-box4 {
    margin-bottom: 20px;
    /* Add space between vertically stacked boxes */
  }

  .text-input {

    width: 90%;
    /* Make the input take up full width */
    height: 50px;
    /* Adjust the height as needed */

  }
}

//Forecaster and analytics screen

.upperContentAndButton {
  display: flex;
  align-items: center;

}

.tryApp1,
.tryApp2 {
  width: 200px;
  height: 40px;
  margin-top: 20px;
  background-color: $mainScreenBackgroundColor;
  color: $lettersColor;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-family: $mainFont;
  font-size: $navigationFontSize;
  color: $lettersColor;
  font-weight: $fontWeight_NavigationLetters;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  margin-left: 40px;
}

.tryApp1:hover,
.tryApp2:hover {
  background-color: $hoverColor;
}

.tryApp1:active,
.tryApp2:active {
  background-color: $navigationBarColor;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

.sendButton {
  width: 90%;
  background-color: #ccc;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 0.7rem;
  font-weight: bold;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.2s ease;
  text-align: center;
  outline: none;

}

.sendButton:hover {
  background-color: #aaa;
}

.sendButton:active {
  background-color: #888;
}

.helpLabel {
  margin-top: 40px;
  margin-right: 250px;
  flex: 1;
  text-align: center;
}

.subHeading {
  margin-top: 10px;
  text-align: center;
  margin-right: 0;
}

@media screen and (max-width: 768px) {

  .tryApp1,
  .tryApp2 {
    margin-left: auto;
    margin-left: 40px;
    margin-top: 40px;
    // margin-bottom: 10px;
    width: 150px;
    height: 35px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  }

  .tryApp1:active,
  .tryApp2:active {
    box-shadow: 2.5px 2.5px 2.5px rgba(0, 0, 0, 0.3);
  }

  .helpLabel {
    margin-right: 200px;
  }
}

.buttonContainer {
  position: fixed;
  top: 360px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: $navigationBarColor;
  width: 50%;
  height: 350px;
  overflow-y: scroll;
  margin-top: 20px;
}

.mainStoryScreen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-top: 10px;
  margin-right: 50px;

}

.forecastsContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  max-width: 90%;
  margin-left: 5%;
  text-align: justify;
  font-size: $navigationFontSize * 1.2;
  font-family: $mainFont;
  font-weight: 100;
  color: $lettersColor;
  line-height: 2;
  background-color: $mainScreenBackgroundColor;
  padding: 40px;

}

@media screen and (max-width: 768px) {
  .buttonContainer {
    top: 355px;
    width: 87%;
  }

  .mainStoryScreen {
    margin-top: -10px;

  }

  .forecastsContainer {
    padding: 20px;

  }
}

@media screen and (max-width: 580px) {
  .helpLabel {
    font-size: $navigationFontSize;
    margin-left: 200px;
  }

  .tryApp1 {
    display: none;


  }

}

@media screen and (max-width: 500px) {
  .helpLabel {
    margin-left: 50px;
    margin-right: 50px;
  }

}

@media screen and (max-width: 450px) {
  .buttonContainer {
    margin-top: 40px;
  }
}

.ads-container {
  position: relative;
  display: block;
  justify-content: space-between;
  align-items: stretch;

}

.right-ad {
  width: 20%;
  margin-left: 77.5%;
  position: fixed;
  top: 180px;
  bottom: 222px;
}

.left-ad {
  width: 20%;
  margin-left: 2.5%;
  position: fixed;
  top: 180px;
  bottom: 222px;
}

.bottom-ad {
  width: 95%;
  height: 100px;
  margin-left: 2.5%;
  position: fixed;
  top: 670px;
  bottom: 0px;
  left: 0;
}

@media screen and (max-width: 768px) {
  .ads-container {
    display: none;
  }

  .bottom-ad {
    width: 100%;
    margin-left: 0%;
    height: 195px;
    margin-bottom: 0px;
    position: fixed;
    left: 0;
    top: 650px;
  }
}

// stock screener and portfolio builder main DIV

.sliderElements {
  position: fixed;
  top: 335px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  background-color: $mainScreenBackgroundColor;
  width: 50%;
  height: 385px;
  overflow-y: scroll;
  margin-top: 20px;
}

h4 {
  // margin: 0;
  // margin-right: 60px;
  font-size: $navigationFontSize;
  font-family: $mainFont;
  font-weight: $fontWeight_Diversset;
  color: $lettersColor;
  text-align: center;
}

.investorTypeSelection {
  width: 90%;
  height: 140px;
  // background-color: red;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  position: relative;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  margin-top: 15px;
  margin-left: 5%;
  border: 0.5px solid #707070;
}

.checkBoxesClass {
  width: 100%;
  height: 70px;
  background-color: $mainScreenBackgroundColor;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  border-bottom-left-radius: 30px;
  border-bottom-right-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkBoxesClass:hover {
  background-color: $elementsHoverColor;
}

.investorTypeSelection:hover .checkBoxesClass {
  background-color: $elementsHoverColor;
}

.slider-parent {
  display: flex;
  align-items: center;
  margin-right: 5%;
}


#riskAmount {
  margin: 0;
  // margin-right: 60px;
  font-size: $navigationFontSize;
  font-family: $mainFont;
  font-weight: $fontWeight_NavigationLetters;
  color: $lettersColor;
  text-align: center;
}

h6 {
  margin: 0;
  margin-right: 20px;
  font-size: $navigationFontSize;
  font-family: $mainFont;
  font-weight: $fontWeight_NavigationLetters;
  color: $lettersColor;
  text-align: center;
}

.header-container {
  align-items: center;
}

.imageRisk {
  margin-top: 10px;
  max-width: 20px;
}

// #questionsStyling {
//  margin-left: 20px;
// }

//Input type range
.custom-range {
  width: 80%;
  /* Expand the range input to fill its container */

}




//screener Button
.goBackButton,
.showTheResultButton {
  width: 200px;
  height: 40px;
  margin-top: 10px;
  background-color: $mainScreenBackgroundColor;
  color: $lettersColor;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-family: $mainFont;
  font-size: $navigationFontSize;
  color: $lettersColor;
  font-weight: $fontWeight_NavigationLetters;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
  border: 0.5px solid #707070;
}

.goBackButton:hover,
.showTheResultButton:hover {
  background-color: $hoverColor;
}

.goBackButton:active,
.showTheResultButton:active {
  background-color: $navigationBarColor;
  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 768px) {

  .goBackButton,
  .showTheResultButton {
    margin-left: auto;
    margin-top: 20px;
    // margin-bottom: 10px;
    width: 150px;
    height: 35px;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  }

  .goBackButton,
  .showTheResultButton {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  }

  .investorTypeSelection {
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
  }

  .sliderElements {
    width: 100%;
    top: 360px;
  }
}

//filtered stocks and ETFs screen

// .dataTable{
//   background-color: #DEE2FF;
//   padding: 2%;
//   width: 50%;
//   height: 20vw;
//   position:absolute;
//   margin-top: 2%;
//   margin-left: 10%;
//   overflow-y: scroll;
// }
.centeredDataTable {
  text-align: center;
  position: fixed;
  top: 360px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 50%;
  height: 350px;
  overflow-y: scroll;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .centeredDataTable {
    width: 90%;
    height: 295px;
  }

}

#buildThePortfolio {
  margin-top: 430px;
  text-align: center;
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
}

//Portfolio builder

.centeredDataTable2 {
  text-align: center;
  position: fixed;
  top: 740px;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 50%;
  height: 385px;
  overflow-y: scroll;
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .centeredDataTable2 {
    width: 90%;
    top: 700px;
  }

  #buildThePortfolio {
    margin-top: 365px;

  }

}

.blog-main-container {
  background-color: #fff;
  max-width: 750px;
  margin: 2rem auto;
  padding: 1.5rem;
  border-radius: 6px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1200px) {
  .blog-main-container {
    max-width: 550px;
  }
}

@media (max-width: 992px) {
  .blog-main-container {
    max-width: 450px;

  }
}

.ads-container {
  display: flex;
  position: relative;

  .left-ad,
  .right-ad {
    width: 200px;
    min-width: 200px;
    background-color: #f9f9f9;
    /* example background or none */
  }

  .bottom-ad {
    /* We'll hide it by default on desktop */
    display: none;
    width: 100%;
    margin-top: 1rem;
    background-color: #f9f9f9;
  }
}

/*  =============================
    Desktop / Large screens
    (>= 769px)
    ============================= */
@media (min-width: 769px) {
  .ads-container {

    .left-ad,
    .right-ad {
      display: block;
      /* Show side ads */
    }

    .bottom-ad {
      display: none;
      /* Hide bottom ad */
    }
  }
}

/*  =============================
    Mobile / Small screens
    (<= 768px)
    ============================= */
@media (max-width: 768px) {
  .ads-container {

    /* On mobile, hide side ads, show bottom ad */
    .left-ad,
    .right-ad {
      display: none;
    }

    .bottom-ad {
      display: block;
      /* Show the bottom ad */
    }
  }

  /* Make the blog container wide,
     but add bottom margin/padding to fit the ad
     if you want extra space for the bottom ad. */
  .blog-main-container {
    max-width: 100%;
    margin-bottom: 6rem;
    /* or however much space needed for bottom ad */
  }
}

.blog-topbar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.blog-heading {
  text-align: center;
  font-size: 1.8rem;
  margin-bottom: 1rem;
}

.blog-img-container {
  text-align: center;
  margin-bottom: 1rem;
}

/* The actual blog image */
.blog-img {
  max-width: 100%;
  height: auto;
  border-radius: 4px;
}

/* Body content */
.blog-body-content {
  line-height: 1.6;
  font-size: 1.1rem;

  /* Make any images that appear inside the blog's HTML responsive too */
  img {
    max-width: 100%;
    height: auto;
    border-radius: 4px;
    display: block;
    margin: 1rem auto;
  }
}

/* The Home button style (tryApp1) - as in Forecaster */
.tryApp1 {
  background-color: #ccc;
  color: #333;
  border: none;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.tryApp1:hover {
  background-color: #b3b3b3;
}

.tryApp1:active {
  background-color: #999;
}

.left-ads,
.right-ads {
  width: 200px;
  min-width: 200px;
  display: block;
  /* show on large screens */
}

.blog-content {
  flex: 1;
  max-width: 700px;
}

/* Hide bottom ads by default on large screens */
.bottom-ads {
  display: none;
}

/* Make images in the body responsive */
.blog-body-content img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 1rem auto;
  /* center it, optional */
}

/* 
   =========== MEDIA QUERY FOR SMALL SCREENS ===========
   We'll assume iPhone ~ (max-width: 768px):
   - Hide left/right columns
   - Make main blog content 100% wide
   - Show bottom-ads 
*/
@media (max-width: 768px) {
  .blog-main-container {
    flex-direction: column;
    /* stack them vertically */
    max-width: 100%;
    /* let it fill screen width */
    padding: 1rem;
  }

  .left-ads,
  .right-ads {
    display: none;
    /* hide side ads */
  }

  .blog-content {
    max-width: 100% !important;
    /* let content be full width */
  }

  .bottom-ads {
    display: block;
    /* show the bottom ad container */
    margin-top: 1rem;
  }
}


.forecaster-container {

  margin: 0 auto;
}

.articles-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
}

.article-card {
  background: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 20px;
  cursor: pointer;
  transition: box-shadow 0.3s, transform 0.2s;
  max-width: 600px;
  width: 100%;
}

.article-card:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  transform: translateY(-5px);
}

.article-title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
  text-align: center;
}

.article-snippet {
  font-size: 0.9em;
  color: #666;
  text-align: justify;
}

.article-detail {
  padding: 20px;
  max-width: 800px;
  margin: 20px auto;
  background: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

.backButton {
  background-color: #ccc;
  ;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s;
}

.backButton:hover {
  background-color: #aaa;
  ;
}

.detail-title {
  font-size: 2em;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.detail-body {
  font-size: 1.2em;
  line-height: 1.8;
  color: #444;
  text-align: justify;
}

@media (max-width: 768px) {
  .article-card {
    max-width: 90%;
  }

  .article-detail {
    max-width: 100%;
  }
}

.detail-body {
  margin-bottom: 1rem;
  line-height: 1.6;
  color: #333;
}