.auth-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f4f4f4;

}

.auth-card {
    background-color: #fff;
    padding: 2rem;
    border-radius: 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    width: 350px;
    text-align: center;
}

.auth-input {
    width: 100%;
    padding: 0.75rem;
    margin: 0.5rem 0;
    font-size: 1rem;
}

.auth-button,
.auth-google-button {
    margin: 1rem 0;
    width: 100%;
    padding: 0.75rem;
    border: none;
    background-color: #0366d6;
    color: white;
    font-weight: bold;
    cursor: pointer;
    border-radius: 4px;
}

.auth-google-button {
    background-color: #db4437;

}

.auth-toggle {
    margin-top: 1rem;
    font-size: 0.95rem;
}

.auth-link {
    color: #0366d6;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 4px;
}

.auth-message {
    margin-top: 0.75rem;
    color: #d9534f;
}